<template>
    <div>
        <v-container class="mainRender" grid-list-md>
            <v-row dense>
                <v-col cols="12" class="text-center py-3">
                    <CardButton :items="items"></CardButton>
                </v-col>
                <v-col cols="12">
                    <TableReservas title="HISTORICO" :lista="_listaVentaHisto" :head="_head"></TableReservas>
                </v-col>
            </v-row>
        </v-container>
        <ActionDial></ActionDial>

        <v-dialog v-model="modalReserva.state" scrollable max-width="1024" persistent>
            <ModalReserva v-if="modalReserva.state" />
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { compareAsc, format, addMonths, subMonths, addMinutes } from 'date-fns'
import CardButton from '@/components/card/CardButton'
import TableReservas from '@/components/reservas/TableReservas'
import LazyLoading from '@/components/loading/LazyLoading'
import ModalReserva from '@/components/modals/ModalReserva'
import ActionDial from '@/components/ActionDial'

function lazyComponent(name) {
    return () => ({
        component: import(`@/components/${name}`),
        loading: LazyLoading
    })
}

export default {
    components: {
        ModalReserva,
        CardButton,
        TableReservas,
        ActionDial
    },
    data: () => ({
        search: null,
        loading: false,
        loadingcaja: false,
        modal: false,
        cerrarCaja: true,
        items: [
            {
                id: 1,
                icon: 'mdi-calendar-blank',
                text: 'VENTAS DEL DÍA',
                state: true
            },
            {
                id: 2,
                icon: 'mdi-calendar-today',
                text: 'HISTORICO 3 MESES',
                state: false
            }
        ],
        price: null,
        description: null,
        datenow: ''
    }),
    computed: {
        ...mapState('ventas', ['modalReserva', 'privadosToday', 'masajesToday', 'mixtosToday', 'productosToday']),
        ...mapState('caja', ['opencaja', 'closeCaja']),
        ...mapGetters('productos', ['listaProductosOut', 'listaCortesiaOut']),
        ...mapGetters('ventas', ['getListaVentaToday']),
        ...mapState('ventas', ['listaVentaHisto']),

        ...mapState('mixtos', ['listaMixto']),
        ...mapState('privados', ['listaHab']),
        ...mapState('masajes', ['listaMassage']),
        ...mapState('sedes', ['sede']),
        ...mapState('users', ['user']),
        ...mapGetters('users', ['optionalRole']),
        _listaVentaHisto() {
            const filterTicketAbierto = this.listaVentaHisto.filter((x) => x.finalizado != 1)
            return filterTicketAbierto
        },
        _head() {
            const lista = [
                { text: 'TICKET', value: '_id' },
                { text: 'SEDE', value: 'sedeDetail' },
                { text: 'Vendedor', value: 'UserAssignment' },
                { text: 'Fecha Creación', value: 'fechaCreacion' },
                { text: 'Fecha Entrega/Fin', value: 'check_in' },
                { text: 'Description', value: '_description' },
                { text: 'Cliente', value: '_full_name' }
            ]
            return lista
        }
    },
    watch: {
        datenow(val) {
            this.listaPrivadosToday
            this.listaMixtosToday
            this.listaMasajesToday
        }
    },
    async created() {
        this.$store.commit('ventas/SHOW_MODAL', { step: 1, state: false })
        this.$store.dispatch('users/getLista'),
            //console.time('get-initial-values')
            await this.getInitialValues()
        //console.timeEnd('get-initial-values')
    },
    async mounted() {
        //console.log(')____________________________')
        await Promise.all([
            this.$store.dispatch('productos/products', { active: 1 }),
            this.$store.dispatch('clientes/getListaCli', { active: 1 }),
            this.$store.dispatch('privados/servicios', { active: 1 })
        ])
        // await this.$store.dispatch('clientes/getListaCli', true)
    },
    beforeDestroy() {},
    methods: {
        async getInitialValues() {
            const { idBussines } = this.user
            //console.log('idBussines ', idBussines)
            let items = [this.getVentaMes(), this.$store.dispatch('sedes/getDepartamento', { online: true })]
            if (idBussines == 1) {
                items.push(this.$store.dispatch('privados/getTable', false))
                items.push(this.$store.dispatch('masajes/getTable', false))
                items.push(this.$store.dispatch('mixtos/getTable', false))
                items.push(this.$store.dispatch('masajes/getLista', false))
                items.push(this.$store.dispatch('mixtos/getLista', false))
                items.push(this.$store.dispatch('privados/getLista', false))
            }
            await Promise.all(items)
        },

        async getVentaMes() {
            await this.$store.dispatch('ventas/getReservas', {})
        },
        time() {
            this.datenow = Date.now()
        },
        UPDATE_CERRAR_CAJA() {
            this.$store.commit('caja/UPDATE_CERRAR_CAJA', true)
        },
        openModal() {
            this.loading = true
            this.$store.commit('ventas/SHOW_MODAL', { step: 1, state: true })
            this.loading = false
        },
        OPEN_CAJA() {
            this.$store.commit('caja/UPDATE_OPEN_CAJA', false)
        }
    }
}
</script>
<style scoped>
.stickyToolbar {
    position: -webkit-sticky !important;
    position: -moz-sticky !important;
    position: -ms-sticky !important;
    position: -o-sticky !important;
    position: sticky !important;
    top: 64px !important;
    z-index: 2;
}
.borderTable {
    border: 1px solid #f5f5f5;
}
@media only screen and (min-width: 1440px) {
    .mainRender {
        min-width: 1500px;
    }
}
</style>
